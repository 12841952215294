// If you want to override variables do it here
@import 'variables';

// Import CoreUI styles
@import '~@coreui/coreui-pro/scss/coreui.scss';

// Some temp fixes
@import 'fixes';

// If you want to add something do it here
@import 'custom';

.table td,
.table th {
  white-space: nowrap;
}

.image-box {
  position: relative;
  /* height: 100px; */
  display: inline-block;
}

.image-delete-button {
  position: absolute;
  top: -12px;
  right: 0px;
  /* z-index: 10000; */
  width: 25px;
}
.ql-viewer p,
.ql-viewer ol,
.ql-viewer ul,
.ql-viewer pre,
.ql-viewer blockquote,
.ql-viewer h1,
.ql-viewer h2,
.ql-viewer h3,
.ql-viewer h4,
.ql-viewer h5,
.ql-viewer h6 {
  margin: 0;
  padding: 0;
}
.ql-viewer {
  padding: 12px 0;
  /* max-width: 500px; */
  width: 100%;
  text-align: unset;
}
.ql-viewer .ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-viewer .ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-viewer .ql-size-small {
  font-size: 0.75em;
}
.ql-viewer .ql-size-large {
  font-size: 1.5em;
}
.ql-viewer .ql-size-huge {
  font-size: 2.5em;
}
.ql-viewer .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-viewer .ql-align-center {
  text-align: center;
}
.ql-viewer .ql-align-justify {
  text-align: justify;
}
.ql-viewer .ql-align-right {
  text-align: right;
}
.ql-viewer blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
}

.ql-viewer code,
.ql-viewer pre {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 6px 10px;
}

.ql-viewer p {
  font-weight: normal;
}

.ql-viewer ul > li[data-checked='true']::before {
  content: '\2611';
}
.ql-viewer ul > li[data-checked='false']::before {
  content: '\2610';
}

.ql-viewer ol > li,
.ql-viewer ul > li {
  list-style-type: none;
}

.ql-viewer img {
  /* max-width: 493px; */
  width: 100% !important;
  margin: 20px 0;
}

.ql-viewer ol {
  counter-reset: mylist;
}

.ql-viewer ol > li:before {
  counter-increment: mylist;
  content: counter(mylist, decimal) '. ';
}
.ql-viewer ol ol > li:before {
  content: counter(mylist, lower-alpha) '. ';
}
.ql-viewer ol ol ol > li:before {
  content: counter(mylist, lower-roman) '. ';
}
.ql-viewer ol ol ol ol > li:before {
  content: counter(mylist, decimal) '. ';
}
.ql-viewer ol ol ol ol ol > li:before {
  content: counter(mylist, lower-alpha) '. ';
}
.ql-viewer ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-roman) '. ';
}
.ql-viewer ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, decimal) '. ';
}
.ql-viewer ol ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-alpha) '. ';
}
.ql-viewer ol ol ol ol ol ol ol ol ol > li:before {
  content: counter(mylist, lower-roman) '. ';
}

/* ql indent */
.ql-viewer .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}

.ql-viewer .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}

.ql-viewer .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}

.ql-viewer .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}

.ql-viewer .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}

.ql-viewer .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}

.ql-viewer .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}

.ql-viewer .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}

.ql-viewer .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}

.ql-viewer .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}

.ql-viewer .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}

.ql-viewer .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}

.ql-viewer .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}

.ql-viewer .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}

.ql-viewer .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}

.ql-viewer .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}

.ql-viewer .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}

.ql-viewer .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}

/* video */
.ql-viewer .ql-video {
  display: block;
  max-width: 100%;
}
.ql-viewer .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-viewer .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
