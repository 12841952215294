// Here you can add other styles

.mobile {
  width: 408px !important;
  max-width: 408px !important;
}

.mobile .ql-editor {
  width: 408px !important;
  max-width: 408px !important;
  height: 1076px !important;
}

.foodThumbnail {
  height: 50px;
  width: 50px;
}

.curationBanner {
  height: 80px;
  width: 160px;
}

#main-logo {
  width: 50%;
}

#sidebar-logo {
  width: 35%;
}

#headerLogo {
  width: 30%;
  margin: auto;
}

img:not(
    #main-logo,
    .curationBanner,
    .foodThumbnail,
    #sidebar-logo,
    #headerLogo,
    #product_thumbnail,
    .photoReview,
    .gallery,
    .pre-image
  ) {
  height: 50%;
  width: 50%;
}

#product_thumbnail {
  width: 150px;
}

.top-buffer-2 {
  margin-top: 2px;
}

.top-buffer-20 {
  margin-top: 20px;
}

.bold {
  font-weight: bold;
}

.gallery {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

a:hover {
  color: black;
  text-decoration: none;
}

.jumbotron {
  background-color: #f9f9f9;
  padding: 1.25rem;
}
